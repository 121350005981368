import React from 'react';
import Logo from '../../assets/white.png';
import fb from "../../assets/icons/fb.png"
import insta from "../../assets/icons/insta.png"
import link from "../../assets/icons/link.png"
import twitter from "../../assets/icons/twitter.png"

// import { Link } from 'react-router-dom'

function FooterNav() {
    return (
        <div className="footer">
            <div className="footer-container">
                <div className="first">
                    <img src={Logo} alt="" /><br />
                    <span>© 2022 Paxoil. All Rights Reserved.</span>
                </div>
                <div className="second">
                    <div className="flex">
                        <img src={fb} alt="" />
                        <img src={twitter} alt="" />
                        <img src={link} alt="" />
                        <img src={insta} alt="" />
                    </div>
                    <div className="flex exernal">
                        <span>Web Design: <a href="https://ensiocreative.com/" target="blank">
                            Ensio Creative
                        </a> </span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FooterNav;