import React, { useEffect } from 'react';
import './home.scss'
import header from "../../assets/paxoil-home-about.png"
import arrow from '../../assets/Arrow11.png'
// import arrow1 from '../../assets/Arrow1.png'
import services from '../../assets/paxoil-home-services.png'
import csr from '../../assets/paxoil-home-csr.png'
import hsp from '../../assets/hsp.png'
import Carousel from '../../components/carousel/carousel'
import { Link } from 'react-router-dom'

function Home() {
    useEffect(() => {
        document.title = "Paxoil - A leading and trusted partner in the Oil & Gas industry."
    })
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])          
    return (
        <div className="home">
            <Carousel />
            <div className="flex">
                <div className="first">
                    <div className="title">We are an indigenous Nigerian servicing company with unbroken commitment to our shared values</div>
                    <div className="desc">Paxoil is an indigenous Nigerian servicing company led by highly skilled professionals in the oil & gas and sector operating independently with flexibility in providing engineering services to our clients from fabrication to project support. We have built mutually productive relationships with major players in the oil & gas and energy sectors.
                    </div>
                    <Link to="/about">
                        <button>
                            Discover more
                            <div><img src={arrow} alt="Discover" /></div>
                        </button>
                    </Link>
                </div>
                <div className="second">
                    <img src={header} alt="header about" />
                </div>
            </div>

            <div className="services first">
                <div>
                    <img src={services} alt="services" />
                </div>
                <div className="second">
                    <div className="title">Our Services</div>
                    <div className="desc">Paxoil is a service provider across the fields of Steel Fabrications, Glassfibre Reinforced Piping System (GRE), Construction, Scaffolding, Rigging, Painting and Sand Blasting, Glassfibre Handrail and Grating, Pipe Wrapping, Electrical & Mechanical Works, Safety Equipment Supplies, Maintenance and Project Support delivering both onshore and offshore.</div>
                    <Link to="/services">
                        <button>
                            Discover more
                            <div><img src={arrow} alt="Discover" /></div>
                        </button>
                    </Link>
                </div>
            </div>


            <div className="hsp">
                <div className="container"></div>
                <img src={hsp} className="hsp-container" alt=""/>
                <div className="overlay">
                    <div className="qoute ">
                        <div className="coma">“</div>
                        <div>Paper doesn’t save people, people save people.</div>
                        <div className="author">Dan Peterson</div>
                        <Link to="/health-safety">
                            <button>
                                Discover more
                                <div><img src={arrow} alt="Discover" /></div>
                            </button>
                        </Link>
                    </div>
                    <div className="second">
                        <div className="title">Health & Safety Policy</div>
                        <div className="line"></div>
                        <div className="desc">
                            We are committed to maintaining highest level of health and safety standards in all our operations at all times knowing that our success is directly linked to the safety of our employees and the public.
                        </div>
                    </div>
                </div>
            </div>
            <div className="services">
                <div>
                    <img src={csr} alt="services" />
                </div>
                <div className="second">
                    <div className="title">Corporate Social Responsibility</div>
                    <div className="desc">At Paxoil, we are committed and relevant member of our host communities and her environ. As a socially responsible servicing company, we have taken Corporate Social Responsibility as an integral part of who we are.</div>
                    <Link to="/csr">
                        <button>
                            Discover more
                            <div><img src={arrow} alt="Discover" /></div>
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default Home;
