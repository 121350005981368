import React, { useEffect } from 'react';
import Hero from '../../components/Hero/hero'
import header from '../../assets/contact-header.png'
import './contact.scss'

function Contact() {
    useEffect(() => {
        document.title = "Contact | Paxoil"
    })
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])    
    const data = [
        {
            title: 'Address',
            description: "16 Pipeline Road, Off Chief Awuse Street, By Cocain Village Back Gate, Port Harcourt",
        },
        {
            title: 'Phone',
            description: '+234 803 706 8309',
        },
        {
            title: 'Email',
            description: 'info@paxoilandgas.com',
        }
    ]
    const contactInfo = data.map((contact, index) => {
        return (
            <div key={index} className="card">
                <div className="title1">{contact.title}</div>
                <div className="line"></div>
                <div className="desc">
                    {contact.description}
                </div>
            </div>
        )
    })
    return (
        <div >
            <Hero img={header} title="Contact us" />
            <div className="contact">
                <div className="title">Get in touch with us today and we will respond promptly.</div>
                <div className="container">
                    {contactInfo}
                </div>
            </div>
        </div>
    );
}

export default Contact;