import './App.scss';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Home from './pages/home/home'
import About from './pages/about/about'
import Contact from './pages/contact/contact'
import Csr from './pages/csr/csr'
import Services from './pages/services/services'
import HealthSafety from './pages/health-safety/health-safety'
// import {useEffect} from "react"
import HeaderNav from './components/Navs/HeaderNav'
import FooterNav from './components/Navs/FooterNav'

function App() {  
  return (
    <Router>
      <HeaderNav />
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/about">
          <About />
        </Route>
        <Route exact path="/contact">
          <Contact />
        </Route>
        <Route exact path="/csr">
          <Csr />
        </Route>
        <Route exact path="/services">
          <Services />
        </Route>
        <Route exact path="/health-safety">
          <HealthSafety />
        </Route>
      </Switch>
      <FooterNav />
    </Router>
  );
}

export default App;
