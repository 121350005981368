import React from 'react';
// import { motion } from 'framer-motion';
import { useState } from 'react'
import img1 from "../../assets/paxoil-fabrication-hero-section.png"
import img2 from "../../assets/paxoil-oil-and-gas-hero-section.png"
import img3 from "../../assets/hero2.png"

import arrow from "../../assets/Arrow4.png"
import './carousel.scss'

const Carousel = () => {
    const sliderData = [
        {
            image: img2,
            title: "A trusted partner in the Oil and Gas industry",
            desc: "With over a decade of operations, Paxoil has become a leader and trusted partner in the oil and gas industry."
        },
        {
            image: img1,
            title: "Fabrication and GRE solutions for industrial needs",
            desc: "We’re focused on providing fabrication and GRE solutions from design to installation for reliable and predictive processperformances"
        },
        {
            image: img3,
            title: "End to end project support",
            desc: "Our teams are experienced in providing support solutions no matter the projection in our field of services."
        },
    ]
    const [current, setCurrent] = useState(0)
    const length = sliderData.length

    const nextSlide = () => {
        setCurrent(current === length - 1 ? 0 : current + 1)
    }
    const prevSlide = () => {
        setCurrent(current === 0 ? length - 1 : current - 1)
    }
    const oil = () => {
        setCurrent(0)
    }
    const fabrication = () => {
        setCurrent(1)
    }
    const support = () => {
        setCurrent(2)
    }
    return (
        <div className="carousel">
            <div className="carousel-inner">
                <img className="left" src={arrow} onClick={prevSlide} alt="" />
                <img className="right" src={arrow} onClick={nextSlide} alt="" />
                {sliderData.map((slides, index) => {
                    return (
                        <div
                            className={index === current ? 'slide active' : 'slide'}
                            key={index}>
                            {index === current && (
                                <img className="image" src={slides.image} alt="" />
                            )}
                            <div className="overlay">
                                <div className="title">{slides.title}</div>
                                <div className="desc">{slides.desc}</div>
                            </div>
                            <div className='flex'>
                                <div onClick={oil}>
                                    <div className="line"></div>
                                    <div className="name">Oil and Gas</div>
                                </div>
                                <div onClick={fabrication}>
                                    <div className="line"></div>
                                    <div className="name">Fabrication & GRE</div>
                                </div>
                                <div onClick={support}>
                                    <div className="line"></div>
                                    <div className="name">Support</div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    );
};

export default Carousel;